// D100
export const D100_1 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/D100/D100_Laser_Driver_1.png";
export const D100_2 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/D100/D100_Laser_Driver_2.png";
export const D100_3 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/D100/D100_Laser_Driver_3.png";
export const D100_4 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/D100/D100_Laser_Driver_4.png";
export const D100_5 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/D100/D100_Laser_Driver_5.png";
export const D100_6 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/D100/D100_Laser_Driver_6.png";
export const D100_7 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/D100/D100_Laser_Driver_7.png";
export const D100_8 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/D100/D100_Laser_Driver_8.png";

// D200
export const D200_1 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/D200/D200_Laser_Driver_1.png";
export const D200_2 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/D200/D200_Laser_Driver_2.png";
export const D200_3 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/D200/D200_Laser_Driver_3.png";
export const D200_4 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/D200/D200_Laser_Driver_4.png";
export const D200_5 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/D200/D200_Laser_Driver_5.png";
export const D200_6 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/D200/D200_Laser_Driver_6.png";

// J240
export const J240_1 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/J240/J240_Pulse_Generator_1.png";
export const J240_2 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/J240/J240_Pulse_Generator_2.png";
export const J240_3 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/J240/J240_Pulse_Generator_3.png";
export const J240_4 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/J240/J240_Pulse_Generator_4.png";
export const J240_5 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/J240/J240_Pulse_Generator_5.png";
export const J240_6 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/J240/J240_Pulse_Generator_6.png";
export const J240_7 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/J240/J240_Pulse_Generator_7.png";

// J270
export const J270_1 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/J270/J270_Pulse_Generator_1.png";
export const J270_2 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/J270/J270_Pulse_Generator_2.png";
export const J270_3 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/J270/J270_Pulse_Generator_3.png";
export const J270_4 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/J270/J270_Pulse_Generator_4.png";
export const J270_5 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/J270/J270_Pulse_Generator_5.png";

// J720
export const J720_1 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/J720/J720_Electrical_Optical_Converter_1.png";
export const J720_2 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/J720/J720_Electrical_Optical_Converter_2.png";
export const J720_3 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/J720/J720_Electrical_Optical_Converter_3.png";
export const J720_4 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/J720/J720_Electrical_Optical_Converter_4.png";
export const J720_5 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/J720/J720_Electrical_Optical_Converter_5.png";
export const J720_6 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/J720/J720_Electrical_Optical_Converter_6.png";

// J724
export const J724_1 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/J724/J724_Electrical_Fiberoptic_Converter_1.png";
export const J724_2 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/J724/J724_Electrical_Fiberoptic_Converter_2.png";
export const J724_3 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/J724/J724_Electrical_Fiberoptic_Converter_3.png";
export const J724_4 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/J724/J724_Electrical_Fiberoptic_Converter_4.png";
export const J724_5 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/J724/J724_Electrical_Fiberoptic_Converter_5.png";
export const J724_6 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/J724/J724_Electrical_Fiberoptic_Converter_6.png";

// J730
export const J730_1 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/J730/J730_Optic_Electric_Converter_1.png";
export const J730_2 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/J730/J730_Optic_Electric_Converter_2.png";
export const J730_3 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/J730/J730_Optic_Electric_Converter_3.png";
export const J730_4 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/J730/J730_Optic_Electric_Converter_4.png";
export const J730_5 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/J730/J730_Optic_Electric_Converter_5.png";
export const J730_6 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/J730/J730_Optic_Electric_Converter_6.png";
export const J730_7 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/J730/J730_Optic_Electric_Converter_7.png";

// J736
export const J736_1 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/J736/J736_Optical_Electrical_Converter_1.png";
export const J736_2 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/J736/J736_Optical_Electrical_Converter_2.png";
export const J736_3 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/J736/J736_Optical_Electrical_Converter_3.png";

// J744
export const J744_1 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/J744/J744_Analog_Optical_Electrical_Converter_1.png";
export const J744_2 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/J744/J744_Analog_Optical_Electrical_Converter_2.png";
export const J744_3 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/J744/J744_Analog_Optical_Electrical_Converter_3.png";
export const J744_4 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/J744/J744_Analog_Optical_Electrical_Converter_4.png";
export const J744_5 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/J744/J744_Analog_Optical_Electrical_Converter_5.png";

// J750
export const J750_1 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/J750/J750_Wideband_Amplifier_1.png";
export const J750_2 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/J750/J750_Wideband_Amplifier_2.png";
export const J750_3 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/J750/J750_Wideband_Amplifier_3.png";
export const J750_4 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/J750/J750_Wideband_Amplifier_4.png";
export const J750_5 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/J750/J750_Wideband_Amplifier_5.png";

// K420
export const K420_1 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/K420/K420_Duplex_Optical_Electrical_Converter_1.png";
export const K420_2 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/K420/K420_Duplex_Optical_Electrical_Converter_2.png";
export const K420_3 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/K420/K420_Duplex_Optical_Electrical_Converter_3.png";
export const K420_4 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/K420/K420_Duplex_Optical_Electrical_Converter_4.png";
export const K420_5 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/K420/K420_Duplex_Optical_Electrical_Converter_5.png";
export const K420_6 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/K420/K420_Duplex_Optical_Electrical_Converter_6.png";

// P330
export const P330_1 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/P330/P330_Capacitive_Level_Sensor_Simulator_1.png";
export const P330_2 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/P330/P330_Capacitive_Level_Sensor_Simulator_2.png";
export const P330_3 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/P330/P330_Capacitive_Level_Sensor_Simulator_3.png";
export const P330_4 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/P330/P330_Capacitive_Level_Sensor_Simulator_4.png";

// P348
export const P348_1 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/P348/P348_IQ_Modulator_1.png";
export const P348_2 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/P348/P348_IQ_Modulator_2.png";
export const P348_3 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/P348/P348_IQ_Modulator_3.png";
export const P348_4 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/P348/P348_IQ_Modulator_4.png";

// P350
export const P350_1 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/P350/P350_Waveform_Playback_ARB_1.png";
export const P350_2 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/P350/P350_Waveform_Playback_ARB_2.png";
export const P350_3 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/P350/P350_Waveform_Playback_ARB_3.png";
export const P350_4 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/P350/P350_Waveform_Playback_ARB_4.png";

// P400
export const P400_1 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/P400/P400_Digital_Delay_Generator_1.png";
export const P400_2 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/P400/P400_Digital_Delay_Generator_2.png";
export const P400_3 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/P400/P400_Digital_Delay_Generator_3.png";
export const P400_4 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/P400/P400_Digital_Delay_Generator_4.png";
export const P400_5 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/P400/P400_Digital_Delay_Generator_5.png";
export const P400_6 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/P400/P400_Digital_Delay_Generator_6.png";

// P470
export const P470_1 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/P470/P470_Thermocouple Simulator_1.png";
export const P470_2 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/P470/P470_Thermocouple Simulator_2.png";
export const P470_3 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/P470/P470_Thermocouple Simulator_3.png";
export const P470_4 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/P470/P470_Thermocouple Simulator_4.png";

// P500
export const P500_1 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/P500/P500_Digital_Delay_Generator_1.png";
export const P500_2 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/P500/P500_Digital_Delay_Generator_2.png";
export const P500_3 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/P500/P500_Digital_Delay_Generator_3.png";
export const P500_4 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/P500/P500_Digital_Delay_Generator_4.png";
export const P500_5 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/P500/P500_Digital_Delay_Generator_5.png";

// P545
export const P545_1 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/P545/P545_Synchro_LVDT_Acquisition_Simulator_1.png";
export const P545_2 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/P545/P545_Synchro_LVDT_Acquisition_Simulator_2.png";
export const P545_3 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/P545/P545_Synchro_LVDT_Acquisition_Simulator_3.png";
export const P545_4 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/P545/P545_Synchro_LVDT_Acquisition_Simulator_4.png";

// P620
export const P620_1 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/P620/P620_Resistance_Simulator_1.png";
export const P620_2 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/P620/P620_Resistance_Simulator_2.png";
export const P620_3 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/P620/P620_Resistance_Simulator_3.png";
export const P620_4 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/P620/P620_Resistance_Simulator_4.png";
export const P620_5 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/P620/P620_Resistance_Simulator_5.png";

// P730
export const P730_1 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/P730/P730_Optical_Electrialc_Fanout_Buffer_1.png";
export const P730_2 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/P730/P730_Optical_Electrialc_Fanout_Buffer_2.png";
export const P730_3 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/P730/P730_Optical_Electrialc_Fanout_Buffer_3.png";
export const P730_4 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/P730/P730_Optical_Electrialc_Fanout_Buffer_4.png";

// P900
export const P900_1 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/P900/P900_Permanent_Magnet_Alternator_Simulator_1.png";
export const P900_2 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/P900/P900_Permanent_Magnet_Alternator_Simulator_2.png";
export const P900_3 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/P900/P900_Permanent_Magnet_Alternator_Simulator_3.png";

// P940
export const P940_1 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/P940/P940_Modular_Power_System_1.png";
export const P940_2 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/P940/P940_Modular_Power_System_2.png";
export const P940_3 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/P940/P940_Modular_Power_System_3.png";
export const P940_4 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/P940/P940_Modular_Power_System_4.png";
export const P940_5 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/P940/P940_Modular_Power_System_5.png";

// P941
export const P941_1 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/P941/P941_Dual_Isolated_DC_Power_Supply_1.png";
export const P941_2 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/P941/P941_Dual_Isolated_DC_Power_Supply_2.png";
export const P941_3 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/P941/P941_Dual_Isolated_DC_Power_Supply_3.png";
export const P941_4 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/P941/P941_Dual_Isolated_DC_Power_Supply_4.png";

// P942
export const P942_1 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/P942/P942_Permanent_Magnet_Alternator_Simulator_1.png";

// P943
export const P943_1 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/P943/P943_DC_Power_Supply_1.png";

// P944
export const P944_1 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/P944/P944_Electronic_Load_1.png";

// P945
export const P945_1 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/P945/P945_Load_Simulator_1.png";
export const P945_2 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/P945/P945_Load_Simulator_2.png";
export const P945_3 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/P945/P945_Load_Simulator_3.png";
export const P945_4 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/P945/P945_Load_Simulator_4.png";

// P948
export const P948_1 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/P948/P948_Cable_Fault_Insertion_2.png";

// P970
export const P970_1 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/P970/P970_Resistive_Inductive_Load.png";

// T124
export const T124_1 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/T124/T124_1_To_4_Logic_Buffer_1.png";
export const T124_2 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/T124/T124_1_To_4_Logic_Buffer_2.png";
export const T124_3 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/T124/T124_1_To_4_Logic_Buffer_3.png";
export const T124_4 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/T124/T124_1_To_4_Logic_Buffer_4.png";
export const T124_5 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/T124/T124_1_To_4_Logic_Buffer_5.png";
export const T124_6 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/T124/T124_1_To_4_Logic_Buffer_6.png";
export const T124_7 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/T124/T124_1_To_4_Logic_Buffer_7.png";

// T130
export const T130_1 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/T130/T130_Picosecond_EOM_Driver_1.png";
export const T130_2 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/T130/T130_Picosecond_EOM_Driver_2.png";
export const T130_3 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/T130/T130_Picosecond_EOM_Driver_3.png";
export const T130_4 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/T130/T130_Picosecond_EOM_Driver_4.png";
export const T130_5 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/T130/T130_Picosecond_EOM_Driver_5.png";

// T160
export const T160_1 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/T160/T160_Laser_Diode_Driver_1.png";
export const T160_2 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/T160/T160_Laser_Diode_Driver_2.png";
export const T160_3 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/T160/T160_Laser_Diode_Driver_3.png";

// T165
export const T165_1 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/T165/T165_Laser_Diode_Pulser_1.png";
export const T165_2 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/T165/T165_Laser_Diode_Pulser_2.png";
export const T165_3 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/T165/T165_Laser_Diode_Pulser_3.png";

// T240
export const T240_1 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/T240/T240_Pulse_Generator_1.png";
export const T240_2 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/T240/T240_Pulse_Generator_2.png";
export const T240_3 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/T240/T240_Pulse_Generator_3.png";
export const T240_4 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/T240/T240_Pulse_Generator_4.png";
export const T240_5 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/T240/T240_Pulse_Generator_5.png";

// T340
export const T340_1 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/T340/T340_Function_Generator_1.png";
export const T340_2 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/T340/T340_Function_Generator_2.png";
export const T340_3 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/T340/T340_Function_Generator_3.png";
export const T340_4 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/T340/T340_Function_Generator_4.png";
export const T340_5 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/T340/T340_Function_Generator_5.png";

// T344
export const T344_1 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/T344/T344_Arbitrary_Waveform_Generator_1.png";
export const T344_2 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/T344/T344_Arbitrary_Waveform_Generator_2.png";
export const T344_3 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/T344/T344_Arbitrary_Waveform_Generator_3.png";
export const T344_4 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/T344/T344_Arbitrary_Waveform_Generator_4.png";
export const T344_5 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/T344/T344_Arbitrary_Waveform_Generator_5.png";
export const T344_6 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/T344/T344_Arbitrary_Waveform_Generator_6.png";

// T346
export const T346_1 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/T346/T346_Arbitrary_Waveform_Generator_1.png";
export const T346_2 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/T346/T346_Arbitrary_Waveform_Generator_2.png";
export const T346_3 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/T346/T346_Arbitrary_Waveform_Generator_3.png";
export const T346_4 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/T346/T346_Arbitrary_Waveform_Generator_4.png";
export const T346_5 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/T346/T346_Arbitrary_Waveform_Generator_5.png";

// T400
export const T400_1 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/T400/T400_Laser_Modulator_System_1.png";
export const T400_2 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/T400/T400_Laser_Modulator_System_2.png";

// T500
export const T500_1 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/T500/T500_Amplitude_Modulator_Chassis_1.png";
export const T500_2 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/T500/T500_Amplitude_Modulator_Chassis_2.png";
export const T500_3 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/T500/T500_Amplitude_Modulator_Chassis_3.png";
export const T500_4 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/T500/T500_Amplitude_Modulator_Chassis_4.png";

// T560
export const T560_1 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/T560/T560_Digital_Delay_Generator_1.png";
export const T560_2 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/T560/T560_Digital_Delay_Generator_2.png";
export const T560_3 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/T560/T560_Digital_Delay_Generator_3.png";
export const T560_4 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/T560/T560_Digital_Delay_Generator_4.png";
export const T560_5 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/T560/T560_Digital_Delay_Generator_5.png";
export const T560_6 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/T560/T560_Digital_Delay_Generator_6.png";
export const T560_7 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/T560/T560_Digital_Delay_Generator_7.png";

// T564
export const T564_1 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/T564/T564_Digital_Delay_Generator_1.png";
export const T564_2 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/T564/T564_Digital_Delay_Generator_2.png";
export const T564_3 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/T564/T564_Digital_Delay_Generator_3.png";
export const T564_4 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/T564/T564_Digital_Delay_Generator_4.png";

// T660
export const T660_1 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/T660/T660_Digital_Delay_Generator_1.png";
export const T660_2 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/T660/T660_Digital_Delay_Generator_2.png";
export const T660_3 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/T660/T660_Digital_Delay_Generator_3.png";
export const T660_4 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/T660/T660_Digital_Delay_Generator_4.png";
export const T660_5 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/T660/T660_Digital_Delay_Generator_5.png";

// T680
export const T680_1 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/T680/T680_Time_Interval_Counter_1.png";
export const T680_2 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/T680/T680_Time_Interval_Counter_2.png";
export const T680_3 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/T680/T680_Time_Interval_Counter_3.png";
export const T680_4 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/T680/T680_Time_Interval_Counter_4.png";
export const T680_5 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/T680/T680_Time_Interval_Counter_5.png";
export const T680_6 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/T680/T680_Time_Interval_Counter_6.png";
export const T680_7 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/T680/T680_Time_Interval_Counter_7.png";

// T735
export const T735_1 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/T735/T735_Electrical_LVDS_Converter_1.png";
export const T735_2 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/T735/T735_Electrical_LVDS_Converter_2.png";
export const T735_3 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/T735/T735_Electrical_LVDS_Converter_3.png";
export const T735_4 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/T735/T735_Electrical_LVDS_Converter_4.png";
export const T735_5 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/T735/T735_Electrical_LVDS_Converter_5.png";

// T750
export const T750_1 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/T750/T750_High_Voltage_Driver_1.png";
export const T750_2 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/T750/T750_High_Voltage_Driver_2.png";
export const T750_3 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/T750/T750_High_Voltage_Driver_3.png";
export const T750_4 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/T750/T750_High_Voltage_Driver_4.png";

// T760
export const T760_1 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/T760/T760_High_Voltage_Optical_Electrical_Converter_1.png";
export const T760_2 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/T760/T760_High_Voltage_Optical_Electrical_Converter_2.png";
export const T760_3 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/T760/T760_High_Voltage_Optical_Electrical_Converter_3.png";

// T840
export const T840_1 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/T840/T840_High_Voltage_Driver_1.png";
export const T840_2 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/T840/T840_High_Voltage_Driver_2.png";
export const T840_3 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/T840/T840_High_Voltage_Driver_3.png";

// T850
export const T850_1 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/T850/T850_Pockels_Cell_Driver_1.png";
export const T850_2 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/T850/T850_Pockels_Cell_Driver_2.png";
export const T850_3 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/T850/T850_Pockels_Cell_Driver_3.png";
export const T850_4 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/T850/T850_Pockels_Cell_Driver_4.png";

// T860
export const T860_1 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/T860/T860_Logic_Buffer_Driver_1.png";
export const T860_2 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/T860/T860_Logic_Buffer_Driver_2.png";
export const T860_3 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/T860/T860_Logic_Buffer_Driver_3.png";
export const T860_4 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/T860/T860_Logic_Buffer_Driver_4.png";
export const T860_5 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/T860/T860_Logic_Buffer_Driver_5.png";

// T900
export const T900_1 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/T900/T900_Laser_Timing_Unit_1.png";

// V120
export const V120_1 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V120/V120_VME_PCIE_Crate_Controller_1.png";
export const V120_2 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V120/V120_VME_PCIE_Crate_Controller_2.png";
export const V120_3 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V120/V120_VME_PCIE_Crate_Controller_3.png";
export const V120_4 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V120/V120_VME_PCIE_Crate_Controller_4.png";

// V124
export const V124_1 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V124/V124_VXI_PCIE_Crate_Controller_15.png";
export const V124_2 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V124/V124_VXI_PCIE_Crate_Controller_2.png";
export const V124_3 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V124/V124_VXI_PCIE_Crate_Controller_3.png";
export const V124_4 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V124/V124_VXI_PCIE_Crate_Controller_4.png";
export const V124_5 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V124/V124_VXI_PCIE_Crate_Controller_5.png";

// V180
export const V180_1 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V180/V180_VME_AC_Power_Analysis_Module_1.png";
export const V180_2 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V180/V180_VME_AC_Power_Analysis_Module_2.png";
export const V180_3 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V180/V180_VME_AC_Power_Analysis_Module_3.png";
export const V180_4 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V180/V180_VME_AC_Power_Analysis_Module_4.png";

// V210
export const V210_1 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V210/V210_VME_Relay_Module_1.png";
export const V210_2 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V210/V210_VME_Relay_Module_2.png";
export const V210_3 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V210/V210_VME_Relay_Module_3.png";
export const V210_4 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V210/V210_VME_Relay_Module_4.png";

// V220
export const V220_1 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V220/V220_VME_Current_Loop_Process_Control_IO_Module_1.png";
export const V220_2 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V220/V220_VME_Current_Loop_Process_Control_IO_Module_2.png";
export const V220_3 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V220/V220_VME_Current_Loop_Process_Control_IO_Module_3.png";
export const V220_4 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V220/V220_VME_Current_Loop_Process_Control_IO_Module_4.png";

// V230
export const V230_1 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V230/V230_VME_Analog_Input_Module_1.png";
export const V230_2 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V230/V230_VME_Analog_Input_Module_2.png";
export const V230_3 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V230/V230_VME_Analog_Input_Module_3.png";
export const V230_4 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V230/V230_VME_Analog_Input_Module_4.png";
export const V230_5 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V230/V230_VME_Analog_Input_Module_5.png";

// V250
export const V250_1 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V250/V250_VME_Digital_Input_Output_Module_1.png";
export const V250_2 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V250/V250_VME_Digital_Input_Output_Module_2.png";
export const V250_3 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V250/V250_VME_Digital_Input_Output_Module_3.png";
export const V250_4 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V250/V250_VME_Digital_Input_Output_Module_4.png";

// V280
export const V280_1 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V280/V280_VME_Digital_Input_Module_1.png";
export const V280_2 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V280/V280_VME_Digital_Input_Module_2.png";
export const V280_3 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V280/V280_VME_Digital_Input_Module_3.png";
export const V280_4 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V280/V280_VME_Digital_Input_Module_4.png";

// V340
export const V340_1 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V340/V340_VME_Function_Generator_1.png";
export const V340_2 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V340/V340_VME_Function_Generator_2.png";
export const V340_3 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V340/V340_VME_Function_Generator_3.png";
export const V340_4 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V340/V340_VME_Function_Generator_4.png";
export const V340_5 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V340/V340_VME_Function_Generator_5.png";

// V344
export const V344_1 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V344/V344_VME_Arbitrary_Waveform_Generator_1.png";
export const V344_2 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V344/V344_VME_Arbitrary_Waveform_Generator_2.png";
export const V344_3 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V344/V344_VME_Arbitrary_Waveform_Generator_3.png";
export const V344_4 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V344/V344_VME_Arbitrary_Waveform_Generator_4.png";

// V346
export const V346_1 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V346/V346_VME_Arbitrary_Waveform_Generator_1.png";
export const V346_2 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V346/V346_VME_Arbitrary_Waveform_Generator_2.png";
export const V346_3 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V346/V346_VME_Arbitrary_Waveform_Generator_3.png";
export const V346_4 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V346/V346_VME_Arbitrary_Waveform_Generator_4.png";

// V350
export const V350_1 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V350/V350_VME_Analog_Digital_Pattern_Generator_1.png";
export const V350_2 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V350/V350_VME_Analog_Digital_Pattern_Generator_2.png";
export const V350_3 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V350/V350_VME_Analog_Digital_Pattern_Generator_3.png";
export const V350_4 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V350/V350_VME_Analog_Digital_Pattern_Generator_4.png";

// V360
export const V360_1 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V360/V360_VME_Tachometer_1.png";
export const V360_2 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V360/V360_VME_Tachometer_2.png";
export const V360_3 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V360/V360_VME_Tachometer_3.png";
export const V360_4 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V360/V360_VME_Tachometer_4.png";

// V365
export const V365_1 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V365/V365_VME_Overspeed_Underspeed_Tachometer_1.png";
export const V365_2 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V365/V365_VME_Overspeed_Underspeed_Tachometer_2.png";
export const V365_3 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V365/V365_VME_Overspeed_Underspeed_Tachometer_3.png";
export const V365_4 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V365/V365_VME_Overspeed_Underspeed_Tachometer_4.png";

// V370
export const V370_1 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V370/V370_VME_Arbitrary_Waveform_Generator_1.png";
export const V370_2 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V370/V370_VME_Arbitrary_Waveform_Generator_2.png";
export const V370_3 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V370/V370_VME_Arbitrary_Waveform_Generator_3.png";

// V375
export const V375_1 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V375/V375_VME_Arbitrary_Waveform_Generator_1.png";
export const V375_2 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V375/V375_VME_Arbitrary_Waveform_Generator_2.png";
export const V375_3 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V375/V375_VME_Arbitrary_Waveform_Generator_3.png";
export const V375_4 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V375/V375_VME_Arbitrary_Waveform_Generator_4.png";

// V380
export const V380_1 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V380/V380_VME_Strain_Gauge_Weighing_Module_1.png";
export const V380_2 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V380/V380_VME_Strain_Gauge_Weighing_Module_2.png";
export const V380_3 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V380/V380_VME_Strain_Gauge_Weighing_Module_3.png";
export const V380_4 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V380/V380_VME_Strain_Gauge_Weighing_Module_4.png";

// V385
export const V385_1 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V385/V385_VME_Strain_Gauge_Load_Cell_Module_1.png";
export const V385_2 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V385/V385_VME_Strain_Gauge_Load_Cell_Module_2.png";
export const V385_3 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V385/V385_VME_Strain_Gauge_Load_Cell_Module_3.png";
export const V385_4 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V385/V385_VME_Strain_Gauge_Load_Cell_Module_4.png";

// V410
export const V410_1 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V410/V410_VME_RTD_Resistance_Input_Module_1.png";
export const V410_2 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V410/V410_VME_RTD_Resistance_Input_Module_2.png";
export const V410_3 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V410/V410_VME_RTD_Resistance_Input_Module_3.png";
export const V410_4 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V410/V410_VME_RTD_Resistance_Input_Module_4.png";

// V420
export const V420_1 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V420/V420_VME_Resistance_Simulator_1.png";
export const V420_2 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V420/V420_VME_Resistance_Simulator_2.png";
export const V420_3 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V420/V420_VME_Resistance_Simulator_3.png";
export const V420_4 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V420/V420_VME_Resistance_Simulator_4.png";

// V450
export const V450_1 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V450/V450_VME_Analog_Thermocouple_Input_Module_1.png";
export const V450_2 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V450/V450_VME_Analog_Thermocouple_Input_Module_2.png";
export const V450_3 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V450/V450_VME_Analog_Thermocouple_Input_Module_3.png";
export const V450_4 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V450/V450_VME_Analog_Thermocouple_Input_Module_4.png";

// V460
export const V460_1 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V460/V460_VME_Current_Source_Analog_Measurement_Module_1.png";
export const V460_2 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V460/V460_VME_Current_Source_Analog_Measurement_Module_2.png";
export const V460_3 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V460/V460_VME_Current_Source_Analog_Measurement_Module_3.png";
export const V460_4 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V460/V460_VME_Current_Source_Analog_Measurement_Module_4.png";

// V470
export const V470_1 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V470/V470_VME_Analog_Output_Thermocouple_Module_1.png";
export const V470_2 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V470/V470_VME_Analog_Output_Thermocouple_Module_2.png";
export const V470_3 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V470/V470_VME_Analog_Output_Thermocouple_Module_3.png";
export const V470_4 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V470/V470_VME_Analog_Output_Thermocouple_Module_4.png";

// V490
export const V490_1 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V490/V490_VME_Multi_Range_Digitizer_1.png";
export const V490_2 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V490/V490_VME_Multi_Range_Digitizer_2.png";
export const V490_3 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V490/V490_VME_Multi_Range_Digitizer_3.png";
export const V490_4 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V490/V490_VME_Multi_Range_Digitizer_4.png";

// V545
export const V545_1 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V545/V545_VME_Synchro_LVDT_Simulator_1.png";
export const V545_2 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V545/V545_VME_Synchro_LVDT_Simulator_2.png";
export const V545_3 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V545/V545_VME_Synchro_LVDT_Simulator_3.png";
export const V545_4 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V545/V545_VME_Synchro_LVDT_Simulator_4.png";

// V550
export const V550_1 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V550/V550_VME_LVDT_RVDT_Scanner_1.png";
export const V550_2 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V550/V550_VME_LVDT_RVDT_Scanner_2.png";
export const V550_3 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V550/V550_VME_LVDT_RVDT_Scanner_3.png";
export const V550_4 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V550/V550_VME_LVDT_RVDT_Scanner_4.png";

// V660
export const V660_1 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V660/V660_VME_Time_Interval_Measurement_1.png";
export const V660_2 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V660/V660_VME_Time_Interval_Measurement_2.png";
export const V660_3 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V660/V660_VME_Time_Interval_Measurement_3.png";
export const V660_4 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V660/V660_VME_Time_Interval_Measurement_4.png";

// V680
export const V680_1 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V680/V680_VME_Time_To_Digital_Converter_1.png";
export const V680_2 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V680/V680_VME_Time_To_Digital_Converter_2.png";
export const V680_3 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V680/V680_VME_Time_To_Digital_Converter_3.png";
export const V680_4 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V680/V680_VME_Time_To_Digital_Converter_4.png";

// V720
export const V720_1 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V720/V720_VME_Electrical_Optical_Converter_1.png";
export const V720_2 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V720/V720_VME_Electrical_Optical_Converter_2.png";
export const V720_3 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V720/V720_VME_Electrical_Optical_Converter_3.png";
export const V720_4 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V720/V720_VME_Electrical_Optical_Converter_4.png";

// V730
export const V730_1 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V730/V730_VME_Optical_Electrical_Converter_1.png";
export const V730_2 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V730/V730_VME_Optical_Electrical_Converter_2.png";
export const V730_3 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V730/V730_VME_Optical_Electrical_Converter_3.png";
export const V730_4 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V730/V730_VME_Optical_Electrical_Converter_4.png";

// V850
export const V850_1 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V850/V850_VME_Digital_Delay_Generator_1.png";
export const V850_2 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V850/V850_VME_Digital_Delay_Generator_2.png";
export const V850_3 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V850/V850_VME_Digital_Delay_Generator_3.png";
export const V850_4 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V850/V850_VME_Digital_Delay_Generator_4.png";

// V851
export const V851_1 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V851/V851_VME_Digital_Delay_Generator_1.png";
export const V851_2 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V851/V851_VME_Digital_Delay_Generator_2.png";
export const V851_3 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V851/V851_VME_Digital_Delay_Generator_3.png";
export const V851_4 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V851/V851_VME_Digital_Delay_Generator_4.png";

// V860
export const V860_1 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V860/V860_VME_Pulse_Amplifier_1.png";
export const V860_2 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V860/V860_VME_Pulse_Amplifier_2.png";
export const V860_3 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V860/V860_VME_Pulse_Amplifier_3.png";

// V880
export const V880_1 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V880/V880_VME_Facility_Timing_Delay_Generator_1.png";
export const V880_2 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V880/V880_VME_Facility_Timing_Delay_Generator_2.png";
export const V880_3 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V880/V880_VME_Facility_Timing_Delay_Generator_3.png";
export const V880_4 =
  "https://highland-technology.sfo2.cdn.digitaloceanspaces.com/products/V880/V880_VME_Facility_Timing_Delay_Generator_4.png";

// Individual Product Photo Arrays
const D100PhotoArr = [D100_1, D100_2, D100_3, D100_4, D100_5, D100_6, D100_7, D100_8,];

const D200PhotoArr = [D200_1, D200_2, D200_3, D200_4, D200_5, D200_6];

const J240PhotoArr = [J240_1, J240_2, J240_3, J240_4, J240_5, J240_6, J240_7];

const J270PhotoArr = [J270_1, J270_2, J270_3, J270_4, J270_5];

const J720PhotoArr = [J720_1, J720_2, J720_3, J720_4, J720_5, J720_6];

const J724PhotoArr = [J724_1, J724_2, J724_3, J724_4, J724_5, J724_6];

const J730PhotoArr = [J730_1, J730_2, J730_3, J730_4, J730_5, J730_6, J730_7];

const J736PhotoArr = [J736_1, J736_2, J736_3];

const J744PhotoArr = [J744_1, J744_2, J744_3, J744_4, J744_5];

const J750PhotoArr = [J750_1, J750_2, J750_3, J750_4, J750_5];

const K420PhotoArr = [K420_1, K420_2, K420_3, K420_4, K420_5, K420_6];

const P330PhotoArr = [P330_1, P330_2, P330_3, P330_4];
const P348PhotoArr = [P348_1, P348_2, P348_3, P348_4];

const P350PhotoArr = [P350_1, P350_2, P350_3, P350_4];

const P400PhotoArr = [P400_1, P400_2, P400_3, P400_4, P400_5, P400_6];

const P470PhotoArr = [P470_1, P470_2, P470_3, P470_4];

const P500PhotoArr = [P500_1, P500_2, P500_3, P500_4, P500_5];

const P545PhotoArr = [P545_1, P545_2, P545_3, P545_4];

const P620PhotoArr = [P620_1, P620_2, P620_3, P620_4, P620_5];

const P730PhotoArr = [P730_1, P730_2, P730_3, P730_4];

const P900PhotoArr = [P900_1, P900_2, P900_3];

const P940PhotoArr = [P940_1, P940_2, P940_3, P940_4, P940_5];

const P941PhotoArr = [P941_1, P941_2, P941_3, P941_4];

const P942PhotoArr = [P942_1];

const P943PhotoArr = [P943_1];

const P944PhotoArr = [P944_1];

const P945PhotoArr = [P945_1, P945_2, P945_3, P945_4];

const P948PhotoArr = [P948_1];

const P970PhotoArr = [P970_1];

const T124PhotoArr = [T124_1, T124_2, T124_3, T124_4, T124_5, T124_6, T124_7];

const T130PhotoArr = [T130_1, T130_2, T130_3, T130_4, T130_5];

const T160PhotoArr = [T160_1, T160_2, T160_3];

const T165PhotoArr = [T165_1, T165_2, T165_3];

const T240PhotoArr = [T240_1, T240_2, T240_3, T240_4, T240_5];

const T340PhotoArr = [T340_1, T340_2, T340_3, T340_4, T340_5];

const T344PhotoArr = [T344_1, T344_2, T344_3, T344_4, T344_5, T344_6];

const T346PhotoArr = [T346_1, T346_2, T346_3, T346_4, T346_5];

const T400PhotoArr = [T400_1, T400_2];

const T500PhotoArr = [T500_1, T500_2, T500_3, T500_4];

const T560PhotoArr = [T560_1, T560_2, T560_3, T560_4, T560_5, T560_6, T560_7];

const T564PhotoArr = [T564_1, T564_2, T564_3, T564_4];

const T660PhotoArr = [T660_1, T660_2, T660_3, T660_4, T660_5];

const T680PhotoArr = [T680_1, T680_2, T680_3, T680_4, T680_5, T680_6, T680_7];

const T735PhotoArr = [T735_1, T735_2, T735_3, T735_4, T735_5];

const T750PhotoArr = [T750_1, T750_2, T750_3, T750_4];

const T760PhotoArr = [T760_1, T760_2, T760_3];

const T840PhotoArr = [T840_1, T840_2, T840_3];

const T850PhotoArr = [T850_1, T850_2, T850_3, T850_4];

const T860PhotoArr = [T860_1, T860_2, T860_3, T860_4, T860_5];

const T900PhotoArr = [T900_1];

const V120PhotoArr = [V120_1, V120_2, V120_3, V120_4];

const V124PhotoArr = [V124_1, V124_2, V124_3, V124_4, V124_5];

const V180PhotoArr = [V180_1, V180_2, V180_3, V180_4];

const V210PhotoArr = [V210_1, V210_2, V210_3, V210_4];

const V220PhotoArr = [V220_1, V220_2, V220_3, V220_4];

const V230PhotoArr = [V230_1, V230_2, V230_3, V230_4, V230_5];

const V250PhotoArr = [V250_1, V250_2, V250_3, V250_4];

const V280PhotoArr = [V280_1, V280_2, V280_3, V280_4];

const V340PhotoArr = [V340_1, V340_2, V340_3, V340_4, V340_5];

const V344PhotoArr = [V344_1, V344_2, V344_3, V344_4];

const V346PhotoArr = [V346_1, V346_2, V346_3, V346_4];

const V350PhotoArr = [V350_1, V350_2, V350_3, V350_4];

const V360PhotoArr = [V360_1, V360_2, V360_3, V360_4];

const V365PhotoArr = [V365_1, V365_2, V365_3, V365_4];

const V370PhotoArr = [V370_1, V370_2, V370_3];

const V375PhotoArr = [V375_1, V375_2, V375_3, V375_4];

const V380PhotoArr = [V380_1, V380_2, V380_3, V380_4];

const V385PhotoArr = [V385_1, V385_2, V385_3, V385_4];

const V410PhotoArr = [V410_1, V410_2, V410_3, V410_4];

const V420PhotoArr = [V420_1, V420_2, V420_3, V420_4];

const V450PhotoArr = [V450_1, V450_2, V450_3, V450_4];

const V460PhotoArr = [V460_1, V460_2, V460_3, V460_4];

const V470PhotoArr = [V470_1, V470_2, V470_3, V470_4];

const V490PhotoArr = [V490_1, V490_2, V490_3, V490_4];

const V545PhotoArr = [V545_1, V545_2, V545_3, V545_4];

const V550PhotoArr = [V550_1, V550_2, V550_3, V550_4];

const V660PhotoArr = [V660_1, V660_2, V660_3, V660_4];

const V680PhotoArr = [V680_1, V680_2, V680_3, V680_4];

const V720PhotoArr = [V720_1, V720_2, V720_3, V720_4];

const V730PhotoArr = [V730_1, V730_2, V730_3, V730_4];

const V850PhotoArr = [V850_1, V850_2, V850_3, V850_4];

const V851PhotoArr = [V851_1, V851_2, V851_3, V851_4];

const V860PhotoArr = [V860_1, V860_2, V860_3];

const V880PhotoArr = [V880_1, V880_2, V880_3, V880_4];

// Array of product photo arrays
export const ProductPhotos = [
  D100PhotoArr,
  D200PhotoArr,
  J240PhotoArr,
  J270PhotoArr,
  J720PhotoArr,
  J724PhotoArr,
  J730PhotoArr,
  J736PhotoArr,
  J744PhotoArr,
  J750PhotoArr,
  K420PhotoArr,
  P330PhotoArr,
  P348PhotoArr,
  P350PhotoArr,
  P470PhotoArr,
  P400PhotoArr,
  P500PhotoArr,
  P545PhotoArr,
  P620PhotoArr,
  P730PhotoArr,
  P900PhotoArr,
  P940PhotoArr,
  P941PhotoArr,
  P942PhotoArr,
  P943PhotoArr,
  P944PhotoArr,
  P945PhotoArr,
  P948PhotoArr,
  P970PhotoArr,
  T124PhotoArr,
  T130PhotoArr,
  T160PhotoArr,
  T165PhotoArr,
  T240PhotoArr,
  T340PhotoArr,
  T344PhotoArr,
  T346PhotoArr,
  T400PhotoArr,
  T500PhotoArr,
  T560PhotoArr,
  T564PhotoArr,
  T660PhotoArr,
  T680PhotoArr,
  T735PhotoArr,
  T750PhotoArr,
  T760PhotoArr,
  T840PhotoArr,
  T850PhotoArr,
  T860PhotoArr,
  T900PhotoArr,
  V120PhotoArr,
  V124PhotoArr,
  V180PhotoArr,
  V210PhotoArr,
  V220PhotoArr,
  V230PhotoArr,
  V250PhotoArr,
  V280PhotoArr,
  V340PhotoArr,
  V344PhotoArr,
  V346PhotoArr,
  V350PhotoArr,
  V360PhotoArr,
  V365PhotoArr,
  V370PhotoArr,
  V375PhotoArr,
  V380PhotoArr,
  V385PhotoArr,
  V410PhotoArr,
  V420PhotoArr,
  V450PhotoArr,
  V460PhotoArr,
  V470PhotoArr,
  V490PhotoArr,
  V545PhotoArr,
  V550PhotoArr,
  V660PhotoArr,
  V680PhotoArr,
  V720PhotoArr,
  V730PhotoArr,
  V850PhotoArr,
  V851PhotoArr,
  V860PhotoArr,
  V880PhotoArr,
];
